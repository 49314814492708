var galleryThumbs = new Swiper('.tab-menu', {
  slidesPerView: 'auto',
  watchSlidesVisibility: true,
  watchSlidesProgress: true,
  slideActiveClass: 'swiper-slide-active',
});
galleryThumbs.on('tap', function () {
  var current = galleryTop.activeIndex;
  galleryThumbs.slideTo(current, 500, true);
});
var galleryTop = new Swiper('.tab-contents', {
  autoHeight: true,
  thumbs: {
    swiper: galleryThumbs
  }
});

var galleryClosets = new Swiper('.slide-menu', {
  slidesPerView: 'auto',
  watchSlidesVisibility: true,
  watchSlidesProgress: true,
  slideActiveClass: 'swiper-slide-active',
  loop: true,

  pagination:{
    el: '.swiper-pagination',
    clickable: true
  },

  navigation:{
    prevEl: '.swiper-button-prev',
    nextEl: '.swiper-button-next',
  },
});

var galleryClosets = new Swiper('.slide-menu2', {
  slidesPerView: 'auto',
  watchSlidesVisibility: true,
  watchSlidesProgress: true,
  slideActiveClass: 'swiper-slide-active',

  pagination:{
    el: '.swiper-pagination',
    clickable: true
  },

  navigation:{
    prevEl: '.swiper-button-prev',
    nextEl: '.swiper-button-next',
  },
});

var window_w = window.innerWidth;
if (window_w >= 650){
  var value = 3;
} else if(window_w < 650){
  var value = 1;
}
var galleryClosets = new Swiper('.slide-menu3', {
  slidesPerView: 'auto',
  watchSlidesVisibility: true,
  watchSlidesProgress: true,
  slideActiveClass: 'swiper-slide-active',
  loop: true,
  loopedSlides: 6,
  speed: 2000,
  slidesPerView: value,

  autoplay: {
    delay: 500,
  },

  pagination:{
    el: '.swiper-pagination',
    clickable: true
  },
});
